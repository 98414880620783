import { Grid } from '@interstate/components/Grid';
import { GridRow } from './GridRow';
import { TermsMonth } from './TermsMonth';
import { Override } from './Override';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';

export const GridTableInterstate: React.FC = () => {
    const paymentGridItems = useSelector(offerReduxSelectors.getPaymentGridItems);
    const paymentGridTermList = useSelector(offerReduxSelectors.getPaymentGridTermsList);

    return (
        <Grid
            data-testid="payment-grid"
            container
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
            sx={{
                '& > div:first-of-type': {
                    borderTopLeftRadius: '0.2rem'
                },
                '& > div:nth-of-type(4)': {
                    borderTopRightRadius: '0.2rem'
                }
            }}
        >
            <TermsMonth paymentGridTermList={paymentGridTermList} />
            {paymentGridItems.map((item) => (
                <Grid
                    key={item.key}
                    display="contents"
                    sx={{
                        '& > div:first-of-type': {
                            background: '#f4f4f4'
                        }
                    }}
                >
                    <GridRow data={item} />
                </Grid>
            ))}
            <Override />
        </Grid>
    );
};
