import React from 'react';
import { LoadingIndicatorWrapper, StyledGridItem, StyledTypography } from './styled.components';
import { PriceInput } from '@interstate/components/PriceInput';
import { Spinner as LoadingIndicator } from '@interstate/components/Spinner';
import { IPaymentTerm } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';

const maxValue = 9999999;

export const GridRow = ({ data }: { data: IPaymentTerm }) => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const dispatch = useDispatch();

    const handleChanges = (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        dispatch(offerReduxActionCreators.updatePaymentGridCashdown(offerType, data.key, e.target.value));
    };

    return (
        <>
            <StyledGridItem xs={3} padding="0 20px" maxHeight="70px">
                <PriceInput
                    autoInsertCommas
                    allowNegative={false}
                    id={'grid-term-input'}
                    data-testid={'grid-term-input'}
                    maxValue={maxValue}
                    allowDecimal={true}
                    decimalMaxLength={2}
                    name={'term-input'}
                    onBlur={handleChanges}
                    value={data.val.toString()}
                />
            </StyledGridItem>
            {data &&
                data.items.map((item) => (
                    <StyledGridItem
                        xs={3}
                        key={item.id}
                        maxHeight="70px"
                        alignItems="center"
                        data-testid="grid-item-row"
                        isSelected={item.isSelected}
                    >
                        {!item.monthlyPayment ? (
                            <LoadingIndicatorWrapper>
                                <LoadingIndicator data-testid="loading-spinner-terms-col" />
                            </LoadingIndicatorWrapper>
                        ) : (
                            <>
                                <StyledTypography>BOA</StyledTypography>
                                <StyledTypography className="large" data-testid="monthly-payment-title">
                                    ${item.monthlyPayment}
                                </StyledTypography>
                                <StyledTypography>{item.sellRate}</StyledTypography>
                            </>
                        )}
                    </StyledGridItem>
                ))}
        </>
    );
};
