// externals
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Modal } from '@interstate/components/Modal';
import { Grid } from '@interstate/components/Grid';
import { Button } from '@interstate/components/Button';
import { Alert } from '@interstate/components/Alert';
import { DocumentDuplicateIcon } from '@interstate-beta/components/Icons/DocumentDuplicateIcon';
import { CheckmarkSolidIcon } from '@interstate-beta/components/Icons/CheckmarkSolidIcon';
import { PushToDmsError } from './PushToDmsError.interstate';
import { Typography } from '@interstate/components/Typography';
import { Box } from '@interstate-beta/components/Box';

// styling
import './pushToDmsDialog.css';

// constants
import {
    ALERT_SUCCESS_MESSAGE,
    DONE_BUTTON_TEXT,
    CLOSE_BUTTON_TEXT,
    DIALOG_HEADER_INTERSTATE,
    PUSH_SUCCESS_DEAL_ID,
    PUSH_SUCCESS_VEHICLE_STOCK_NUMBER,
    PUSH_SUCCESS_VEHICLE_VIN,
    CANCEL_BUTTON_TEXT
} from '../pushToDmsConstants';

// libraries
import { CDMS, DMS_PLUS, pushToDmsActionCreators } from '@makemydeal/dr-dash-store';

// types
import { PushToDmsStatus } from '../pushToDmsTypes';

// selectors
import { pushToDmsSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { FooterGrid } from './PushToDmsDialog.style';

export interface IPushToDmsDialogProps {
    show: boolean;
    status: string;
    onHide(): void;
    onDone(): void;
    isPushToCdmsActive: boolean;
}

const PushToDmsDialog = (props: IPushToDmsDialogProps) => {
    const { show, status, onHide, onDone, isPushToCdmsActive } = props;

    const dispatch = useDispatch();

    const prefix = 'push-to-dms-dialog';

    const pushToDmsErrorRequest = useSelector(pushToDmsSelectors.getPushToDmsError);
    const returnedSuccessfulCdmsResponse = useSelector(pushToDmsSelectors.getReturnedSuccessfulCdmsResponse);
    const cdmsResponseStatusCode = useSelector(pushToDmsSelectors.getCdmsResponseStatusCode);
    const cdmsErrorMessage = useSelector(pushToDmsSelectors.getCdmsResponseErrorMessage);
    const vehicleStockNumber = useSelector(vehicleSelectors.getVehicleStockNumber);
    const isEnableOptionalPbcUi = useSelector(featureToggleSelectors.enableOptionalPbcUi);
    const vehicleVin = useSelector(vehicleSelectors.getVehicleVin);
    const lastSucceededDmsDealId = useSelector(pushToDmsSelectors.getLastSucceededDmsDealId);
    const [copiedItems, setCopiedItems] = useState<Record<string, boolean>>({});
    const dmsIntegrationToggle = useSelector(dealerSelectors.getDMSIntegrationToggle);
    const isCdmsPbcIntegration = isEnableOptionalPbcUi && dmsIntegrationToggle === CDMS;

    const handleCopyClick = () => {
        navigator.clipboard.writeText(vehicleStockNumber);
        dispatch(pushToDmsActionCreators.copyDmsStockNumber(vehicleStockNumber));
    };

    const handleCdmsCopyClick = (key: string, value: string) => {
        navigator.clipboard.writeText(value);
        setCopiedItems({ ...copiedItems, [key]: true });
        setTimeout(() => setCopiedItems((newState) => ({ ...newState, [key]: false })), 2000);
    };

    const successMessageFields = [
        { key: 'dealId', label: PUSH_SUCCESS_DEAL_ID, value: lastSucceededDmsDealId },
        { key: 'stockNumber', label: PUSH_SUCCESS_VEHICLE_STOCK_NUMBER, value: vehicleStockNumber },
        { key: 'vin', label: PUSH_SUCCESS_VEHICLE_VIN, value: vehicleVin }
    ].map((record) => ({
        ...record,
        handleCopy: () => handleCdmsCopyClick(record.key, record.value)
    }));

    const header = (
        <Typography
            data-testid={`${prefix}-header`}
            variant={isCdmsPbcIntegration ? 'h3' : 'h4'}
            tag={isCdmsPbcIntegration ? 'h3' : 'h4'}
            color={isCdmsPbcIntegration ? 'sem.color.on-surface.default' : 'sem.color.on-surface.primary'}
        >
            {DIALOG_HEADER_INTERSTATE}
        </Typography>
    );
    const footer =
        status === PushToDmsStatus.Failure ? (
            <FooterGrid container justifyContent="flex-end" data-testid={`${prefix}-footer`}>
                <Button
                    id={`${prefix}-close-button`}
                    data-testid={`${prefix}-close-button`}
                    buttonStyle={isCdmsPbcIntegration ? 'tertiary' : 'secondary'}
                    onClick={onDone}
                >
                    {isCdmsPbcIntegration ? CANCEL_BUTTON_TEXT : CLOSE_BUTTON_TEXT}
                </Button>
            </FooterGrid>
        ) : (
            <FooterGrid container justifyContent="flex-end" data-testid={`${prefix}-footer`}>
                <Button
                    id={`${prefix}-done-button`}
                    data-testid={`${prefix}-done-button`}
                    buttonStyle={isCdmsPbcIntegration ? 'tertiary' : 'primary'}
                    onClick={onDone}
                >
                    {CLOSE_BUTTON_TEXT}
                </Button>
            </FooterGrid>
        );

    if (isCdmsPbcIntegration && !isPushToCdmsActive) return null;

    return dmsIntegrationToggle === DMS_PLUS || (dmsIntegrationToggle === CDMS && !isEnableOptionalPbcUi) ? (
        <Modal
            size="large"
            data-testid={prefix}
            show={show}
            header={header}
            footer={{ footerComponent: footer }}
            onHide={onHide}
            sx={{
                '.MuiDialog-paper': {
                    width: 'auto'
                }
            }}
        >
            {status === PushToDmsStatus.Failure ? (
                <PushToDmsError
                    statusCode={returnedSuccessfulCdmsResponse ? pushToDmsErrorRequest.statusCode : cdmsResponseStatusCode}
                    errorDetails={returnedSuccessfulCdmsResponse ? pushToDmsErrorRequest.errorDetails : cdmsErrorMessage}
                />
            ) : (
                <Grid container flexDirection="column" paddingX={3} gap={2} width="320px">
                    <Alert id={`${prefix}-alert`} type="success" disableSuccessFade>
                        {ALERT_SUCCESS_MESSAGE}
                    </Alert>
                    <Grid container flexWrap="nowrap" alignItems="center" gap={1}>
                        <Typography
                            data-testid={`${prefix}-stock-number-input`}
                            variant="body-sm"
                            tag="p"
                            color="sem.color.on-surface.primary"
                        >
                            {vehicleStockNumber}
                        </Typography>
                        <Button
                            data-testid={`${prefix}-copy-button`}
                            buttonStyle="secondary"
                            onClick={handleCopyClick}
                            size="medium"
                        >
                            <DocumentDuplicateIcon id="IconNoteExample" />
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Modal>
    ) : (
        <Modal size={'large'} data-testid={prefix} show={show} header={header} footer={{ footerComponent: footer }} onHide={onHide}>
            {status === PushToDmsStatus.Failure ? (
                <PushToDmsError
                    statusCode={returnedSuccessfulCdmsResponse ? pushToDmsErrorRequest.statusCode : cdmsResponseStatusCode}
                    errorDetails={returnedSuccessfulCdmsResponse ? pushToDmsErrorRequest.errorDetails : cdmsErrorMessage}
                />
            ) : (
                <Grid container flexDirection="column" paddingX={0} gap={2} width={'auto'}>
                    <Alert id={`${prefix}-alert`} type="success" disableSuccessFade>
                        {ALERT_SUCCESS_MESSAGE}
                    </Alert>
                    <Box
                        sx={{ display: 'grid', 'grid-template-columns': '200px auto' }}
                        data-testid="pbc-ui-success-dialog-copy-container"
                        pl={1}
                    >
                        {successMessageFields.map((field) => {
                            return (
                                <>
                                    <Box key={`${field.key}-label-value`}>
                                        <Typography
                                            data-testid={`${prefix}-${field.key}-label`}
                                            variant="body-md"
                                            color="sem.color.on-surface.muted"
                                        >
                                            {field.label}
                                        </Typography>
                                        <Typography
                                            data-testid={`${prefix}-${field.key}-value`}
                                            variant="body-md"
                                            sx={{ paddingTop: 2, paddingBottom: 3 }}
                                        >
                                            {field.value}
                                        </Typography>
                                    </Box>
                                    <Box key={`${prefix}-${field.key}-copy`} display="flex" alignItems="center" gap={1}>
                                        <Button
                                            data-testid={`${prefix}-${field.key}-copy-button`}
                                            buttonStyle="secondary"
                                            onClick={field.handleCopy}
                                            size="medium"
                                        >
                                            {copiedItems[field.key] ? (
                                                <CheckmarkSolidIcon data-testid={`${prefix}-${field.key}-copied-icon`} />
                                            ) : (
                                                <DocumentDuplicateIcon data-testid={`${prefix}-${field.key}-copy-icon`} />
                                            )}
                                        </Button>
                                    </Box>
                                </>
                            );
                        })}
                    </Box>
                </Grid>
            )}
        </Modal>
    );
};

export default PushToDmsDialog;
