// istanbul ignore file
// This file ONLY re-exports offer-redux selectors. If we add new logic, it belongs in a different module
import { selectors } from '@makemydeal/dr-offer-redux';
export const {
    findAllIncentiveTotalFromOffer,
    getAcqFeeOverride,
    getAcqFeeUpFrontOverride,
    getAcquisitionFeeFromTerm,
    getAdjustedCapCost,
    getAdjustedResidualValue,
    getAdvanceFlatTax,
    getAdvanceSalesTax,
    getAllIncentiveTotal,
    getAllIncentiveTotalByOfferType,
    getAllTaxesItemized,
    getAllTaxesItemizedWithoutFlat,
    getTaxItemsWithOverridesWithoutFlat,
    getTaxItemsWithAdjustedRates,
    getAnnualMiles,
    getAnnualMilesOptions,
    getAppliedConditionalIncentives,
    getAppliedConditionalIncentivesAmount,
    getAppliedNonconditionalIncentivesAmount,
    getAppliedIncentives,
    getAppliedIncentivesForNonDealerCash,
    getAppliedIncentivesMappedForAIO,
    getAppliedIncentivesTotalForDealerCash,
    getAppliedIncentivesTotalForNonDealerCash,
    getApr,
    getAvailableFinanceTermsOptions,
    getAvailableLeaseTermsOptions,
    getBaseMonthlyPayment,
    getBaseMonthlyPaymentTotal,
    getBaseResidualDollar,
    getBaseResidualPercent,
    getBuyRate,
    getBuyRateOverride,
    getCapCostReduction,
    getCapCostReductionTax,
    getCapCostTotal,
    getCapitalizedTaxTotal,
    getCapitalizedFeesByOfferType,
    getCapReductionRebate,
    getCapReductionTradeIn,
    getCashOfferRoundedUp,
    getCmdMessageToSalesperson,
    getCmdMessageToShopper,
    getCoBuyerCommonConsumerId,
    getCoBuyerInfo,
    getCoBuyerFullName,
    getCommonOrgId,
    getCreditAppCoApplicantEmail,
    getCreditAppId,
    getCreditDecisionEnabled,
    getCreditDecisionLender,
    getCreditDecisionLenderName,
    getCreditDecisionStatus,
    getCreditTierById,
    getCreditTiers,
    getCurrentCalculationEndpoint,
    getCurrentOfferType,
    getDaysToFirstPayment,
    getDealRefId,
    getDealRefIdFS,
    getDealXgId,
    getDealXgVersion,
    getDealerAcquisitionFeeFromTerm,
    getDealerFees,
    getDealerFeesTotalFromTerm,
    getDeviceType,
    getDiscount,
    getDmvFeesFromTerm,
    getDownPaymentByOfferType,
    getDueAtSigning,
    getDueAtSigningFromDueInAdvance,
    getExcessMileageCharge,
    getFsDealRefId,
    getGlobalCustomerId,
    getGovernmentFees,
    getGovernmentUpfrontFeeTotal,
    getIncentivesTotalOverride,
    getIncentivesTotalOverrideByOfferType,
    getIsUserProgramQuotes,
    getLenderLegalName,
    getLenderProgramEndDate,
    getManualCreditScore,
    getManualIncentiveRebatesByOfferType,
    getManualIncentives,
    getManualTotalTax,
    getMaxResidualizedMsrp,
    getMaxResidualizedMsrpApplied,
    getMessage,
    getMessagesFromCurrentTerm,
    getMileageChargeOverride,
    getMonthlyPaymentByOfferType,
    getMonthlyPaymentRoundedByOfferType,
    getMonthlyRentCharged,
    getMonthlyUseTax,
    getNetCashDown,
    getNonConditionalTotal,
    getNonConditionalsByOfferType,
    getOfferCreationDate,
    getOfferCreationDateString,
    getOfferDetails,
    getOfferId,
    getOfferPrice,
    getOfferPriceByOfferType,
    getOfferSource,
    getOfferDealerSource,
    getOfferSummary,
    getPaymentGridToggle,
    getPaymentProducts,
    getPrimaryTaxBreakdown,
    getRateType,
    getResidualInfoOverride,
    getRetailPriceOverride,
    getRetailPriceOverrideByOfferType,
    getSecurityDepositOverride,
    getSelectedCapCostReduction,
    getSelectedConditionalIncentivesToDisplay,
    getSelectedConditionalTotal,
    getSelectedCreditTierIdByType,
    getSelectedCreditTierScore,
    getSelectedIncentivesByOfferType,
    getSelectedNonConditionalIncentivesToDisplay,
    getSelectedPaymentProfitDetails,
    getSelectedTerm,
    getSelectedTermMonths,
    getSelectedTermByOfferType,
    getSelectedTermAmountFinanced,
    getSellRate,
    getSellRateOverride,
    getSessionId,
    getShopperCommonConsumerId,
    getShopperInfo,
    getShopperZip,
    getShortDisclaimerData,
    getTaxBreakdown,
    getTaxOverrideUpfront,
    getTaxesAndFeesTotal,
    getTerm,
    getTermId,
    getTermIdByOfferType,
    getTermIdForOfferType,
    getTermsByIdForOffer,
    getTotalAmountDue,
    getCashTotalAmountDueAfterCredits,
    getTotalDepreciation,
    getTotalInterest,
    getTotalTax,
    getTotalTaxesAndFees,
    getTotalUnifiFees,
    getUpfrontAddonsTotal,
    getUpfrontFeesTotal,
    getUpfrontTaxTotal,
    getVehicleDetails,
    hasCoBuyer,
    hasManualTotalTax,
    hasDealBeenAccepted,
    isMonthlyPaymentInvalid,
    isOfferFromVinSource,
    isTaxCapped,
    isTaxResetDisabled,
    hasMissingPaymentData,
    hasManualTaxItems,
    getTaxItemsWithOverrides,
    getItemizedLenderFees,
    getPaymentGridItems,
    getPaymentGridTermsList
} = selectors;
