import styled from 'styled-components';
import { Grid } from '@interstate/components/Grid';

export const GridItem = styled(Grid)`
    border: 1px solid #b2b2b2;
    width: 120px;
    height: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
`;

export const StyledGridItem = styled(Grid)<{ isSelected?: boolean }>`
    border: 1px solid #b2b2b2;
    width: 120px;
    height: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    background-color: ${(props) => (props.isSelected ? '#003468' : '#ffffff')};
    color: ${(props) => (props.isSelected ? 'white' : '#16171A')};
`;

export const StyledTypography = styled.div`
    font-size: 12px;
    &.large {
        font-size: 16px;
    }
`;

export const LoadingIndicatorWrapper = styled.div`
    position: relative;

    & #interstitial-overlay-root {
        background-color: transparent;
    }
    & #ids-spinner-root {
        color: gray;
    }
    & #interstitial-content-root {
        color: gray;
    }
`;
