// side-nav
export const PUSH_TO_DMS_BUTTON_TEXT = 'Push To DMS';
export const RESEND_TO_DMS_BUTTON_TEXT = 'Resend to DMS';
export const RE_PUSH_TO_DMS_BUTTON_TEXT = 'Re-Push To DMS';
// modal
export const DIALOG_HEADER_INTERSTATE = 'Push To DMS';
export const DIALOG_HEADER_RE_PUSH_INTERSTATE = 'Re-Pushing Deal To DMS';
export const DIALOG_HEADER = 'Confirm Push to DMS';
export const PUSH_TO_DMS_DIALOG_HEADER = 'Push to DMS';
export const DIALOG_PROMPT = 'Make sure all financial and contracting activities have been completed before pushing to DMS.';
export const IMPORTANT_DIALOG_PROMPT =
    'Re-pushing a deal to the DMS can potentially cause data loss. Be aware of this risk before proceeding.';
export const ALERT_SUCCESS_MESSAGE = 'Push to DMS was successful.';
export const LOADING_TEXT = 'Pushing to DMS';
export const LOADING_TEXT_INTERSTATE = 'Updating...';
export const CANCEL_BUTTON_TEXT = 'Cancel';
export const CONFIRM_BUTTON_TEXT = 'Confirm';
export const CONTINUE_BUTTON_TEXT = 'Continue';
export const DONE_BUTTON_TEXT = 'Done';
export const CLOSE_BUTTON_TEXT = 'Close';
export const PUSH_ERROR_DIALOG_HEADER = 'Push To DMS Failed';
export const PUSH_ERROR_DIALOG_HEADER_INTERSTATE = 'Push To DMS Failed.';
export const PUSH_VIN_ERROR_DIALOG_MESSAGE = 'You must enter a full 17 digit VIN for the trade-in vehicle.';
export const SHOW_WAITING_LOADER_TEXT = 'This will take a moment';
export const PUSH_SUCCESS_DEAL_ID = 'Deal ID';
export const PUSH_SUCCESS_VEHICLE_STOCK_NUMBER = 'Stock #';
export const PUSH_SUCCESS_VEHICLE_VIN = 'VIN #';
export const PUSH_CDMS_ERROR_MESSAGE = 'This deal could not be pushed to the DMS. Please try again.';
