// externals
import { Dispatch, SetStateAction, useCallback, useMemo, useState, type FC } from 'react';

// libraries
import { Box } from '@interstate/components/Box';
import { Button } from '@interstate/components/Button';

// components
import { FeeResetDialog } from './FeeResetDialog';
import FeesFormCategoryItems from './FeesFormCategoryItems';
import { FeesFormContext, FeesFormContextProps } from './FeesFormContext';

// styles
import { FlexFeesButtonContainer } from './FeesForm.style';

// types/consts/enums
import { CASH, LEASE, paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { FeesCategoriesType } from '@makemydeal/dr-shared-types';
import { ELIMINATED_FEE_AMOUNT } from './constants';
import { CustomFeeItem, Detail, LeaseDetail } from './types';

// utils
import { createNewFee } from '../../utils/components';
import { getAreFeesValid, hasChangeDealerFees, hasChangeGovFees, hasChangeLenderFees, mergeFeeUpdates } from './utils';

type FeesFormProps = {
    toggleShowEditAction: () => void;
    offerType: string;
    governmentFeesItems: CustomFeeItem[];
    dealerFeesItems: CustomFeeItem[];
    lenderFeesItems: CustomFeeItem[];
    onReset?: () => void;
    onSave?: (updatedFees: paymentServicesTypes.FeeOverride[]) => void;
};

export const FeesForm: FC<FeesFormProps> = ({
    offerType,
    toggleShowEditAction,
    governmentFeesItems,
    dealerFeesItems,
    lenderFeesItems,
    onReset,
    onSave
}) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [govFees, setGovFees] = useState(governmentFeesItems);
    const [dealerFees, setDealerFees] = useState(dealerFeesItems);
    const [lenderFees, setLenderFees] = useState(lenderFeesItems);
    const [disableFeeSubmit, setDisableFeeSubmit] = useState(false);

    const hasChangedFees = useMemo(
        () =>
            hasChangeGovFees({
                initialGovFees: governmentFeesItems,
                updatedGovFees: govFees
            }) ||
            hasChangeDealerFees({ initialDealerFees: dealerFeesItems, updatedDealerFees: dealerFees }) ||
            hasChangeLenderFees({ initialLenderFees: lenderFeesItems, updatedLenderFees: lenderFees }),
        [governmentFeesItems, govFees, dealerFeesItems, dealerFees, lenderFeesItems, lenderFees]
    );
    const areFeesValid = getAreFeesValid(dealerFees, govFees);
    const isLease = offerType === LEASE;
    const isCash = offerType === CASH;

    const handleDelete: FeesFormContextProps['handleDelete'] = useCallback(({ category, code, isDelete, index }) => {
        const deleteByCategoryLookup: Partial<Record<paymentServicesTypes.FeeCategory, Dispatch<SetStateAction<CustomFeeItem[]>>>> =
            {
                Government: setGovFees,
                Dealer: setDealerFees,
                Lender: setLenderFees
            };

        const setStateFunc = deleteByCategoryLookup[category];
        if (!setStateFunc) return;

        if (!code) {
            setStateFunc((fees) => fees.filter((fee, ind) => fee.feeTypeId !== code && ind !== index));
            return;
        }

        setStateFunc((fees) =>
            fees.map((fee, ind) =>
                fee.feeTypeId === code && ind === index
                    ? {
                          ...fee,
                          isDeleted: isDelete
                      }
                    : fee
            )
        );
    }, []);

    const handleInputChange: FeesFormContextProps['handleInputChange'] = useCallback(
        ({ category, field, index, value }) => {
            const updateByCategoryLookup: Partial<
                Record<paymentServicesTypes.FeeCategory, Dispatch<SetStateAction<CustomFeeItem[]>>>
            > = {
                Government: setGovFees,
                Dealer: setDealerFees,
                Lender: setLenderFees
            };

            updateByCategoryLookup[category]?.((prev) => {
                const updatedFees = [...prev];

                updatedFees[index] = { ...updatedFees[index], [field]: value, isEdited: true };

                return updatedFees;
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dealerFees, govFees]
    );

    const handleAddFee: FeesFormContextProps['handleAddFee'] = useCallback(
        ({ category, feeItems }) => {
            const newFee: Detail = {
                name: '',
                code: undefined,
                amount: '1',
                category,
                isManual: true,
                isEdited: true,
                includedInCalc: true
            };

            if (isLease) {
                (newFee as LeaseDetail).upfront = false;
            }

            const addNewFeeByCategoryLookup = {
                [FeesCategoriesType.DEALER]: () => setDealerFees([...feeItems, createNewFee(isLease, category)]),
                [FeesCategoriesType.GOVERNMENT]: () => setGovFees([...feeItems, createNewFee(isLease, category)]),
                [FeesCategoriesType.LENDER]: () => setLenderFees([...feeItems, createNewFee(isLease, category)])
            };

            addNewFeeByCategoryLookup[category]();
        },
        [isLease]
    );

    const handleSave = () => {
        const updatedFees = [...govFees, ...dealerFees];

        updatedFees.push(...lenderFees);

        const filteredUpdatedFees = updatedFees.filter((fees) => fees.isEdited || fees.isDeleted);

        const uniqueFilteredUpdatedFees = mergeFeeUpdates(filteredUpdatedFees);

        const feesOverrides = uniqueFilteredUpdatedFees.map<paymentServicesTypes.FeeOverride>((fee) => ({
            category: fee.category,
            feeAmount: fee.isDeleted ? ELIMINATED_FEE_AMOUNT : Number(fee.feeAmount),
            feeDescription: fee.feeDescription,
            feeName: fee.feeName,
            feeTypeId: Number(fee.feeTypeId),
            includeInCalcIndicator: fee.includedInCalc,
            reqCapIndicator: fee.capIndicator
        }));

        onSave?.(feesOverrides);
        toggleShowEditAction();
    };

    const handleConfirmDialogHide = () => {
        setShowConfirmDialog(false);
    };

    const handleConfirmDialogDone = useCallback(() => {
        setShowConfirmDialog(false);
        setGovFees(governmentFeesItems);
        setDealerFees(dealerFeesItems);
        onReset?.();
    }, [dealerFeesItems, governmentFeesItems, onReset]);

    const handleConfigDialogCancel = () => {
        setShowConfirmDialog(false);
    };

    const handleResetClick = () => {
        setShowConfirmDialog(true);
    };

    const getIsIncludeInCalc: FeesFormContextProps['getIsIncludeInCalc'] = useCallback(
        ({ category, includedInCalc }) => {
            return !includedInCalc && category === FeesCategoriesType.LENDER && isLease;
        },
        [isLease]
    );

    const getCurrentlySelectedFees = useCallback(() => {
        return [...lenderFees, ...govFees, ...dealerFees];
    }, [lenderFees, govFees, dealerFees]);

    const providerValue: FeesFormContextProps = useMemo(
        () => ({
            handleInputChange,
            handleDelete,
            setGovFees,
            setDealerFees,
            setLenderFees,
            getIsIncludeInCalc,
            handleAddFee,
            isLease,
            setDisableFeeSubmit,
            dealerFees,
            govFees,
            lenderFees,
            getCurrentlySelectedFees
        }),
        [
            dealerFees,
            getIsIncludeInCalc,
            govFees,
            handleAddFee,
            handleDelete,
            handleInputChange,
            isLease,
            lenderFees,
            getCurrentlySelectedFees
        ]
    );

    return (
        <Box>
            <FeesFormContext.Provider value={providerValue}>
                {!isCash && (
                    <FeesFormCategoryItems
                        categoryTitle="Lender Fees"
                        feeItems={lenderFees}
                        categoryType="Lender"
                        dataTestId="form_category_lender_fees"
                    />
                )}
                <FeesFormCategoryItems
                    categoryTitle="Government Fees"
                    feeItems={govFees}
                    categoryType="Government"
                    dataTestId="form_category_gov_fees"
                />
                <FeesFormCategoryItems
                    categoryType="Dealer"
                    categoryTitle="Dealer Fees"
                    feeItems={dealerFees}
                    dataTestId="form_category_dealer_fees"
                />
            </FeesFormContext.Provider>

            <FlexFeesButtonContainer id="deal-fees-button-container">
                <Button data-testid="btn-deal-fees-edit-cancel" buttonStyle="tertiary" type="submit" onClick={toggleShowEditAction}>
                    Cancel
                </Button>
                <Button data-testid="btn-deal-fees-edit-reset" buttonStyle="secondary" type="reset" onClick={handleResetClick}>
                    Reset All
                </Button>
                <Button
                    data-testid="btn-deal-fees-edit-save"
                    buttonStyle="primary"
                    type="submit"
                    disabled={disableFeeSubmit || !hasChangedFees || !areFeesValid}
                    onClick={handleSave}
                >
                    Save
                </Button>
                {showConfirmDialog && (
                    <FeeResetDialog
                        data-testid="reset-fees-confirm-dialog"
                        onHide={handleConfirmDialogHide}
                        onConfirm={handleConfirmDialogDone}
                        onCancel={handleConfigDialogCancel}
                    />
                )}
            </FlexFeesButtonContainer>
        </Box>
    );
};

export default FeesForm;
