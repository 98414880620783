// externals
import { useDispatch, useSelector } from 'react-redux';

// components
import { LoadingSectionParent, PaymentLoading, FlexCol } from '@makemydeal/dr-dash-components';
import { Card, CardHeader } from '@interstate/components/Card';

import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { Badge } from '@interstate/components/Badge';
import { CheckBox, CheckBoxEventValue } from '@interstate/components/CheckBox';
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { Alert } from '@interstate/components/Alert';
import TriforceWidget from './triforceWidget/TriforceWidget';

// styling
import { DetailContainer, TradeCheckboxContainer, TradeInSeparator, VehicleDetailContent } from './trade.interstate.style';

// selectors
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { configSelectors, tradeInSelectors, vAutoTriforceSelectors } from '@makemydeal/dr-dash-store';

// constants
import { LEASE, LEASE_TRADE, INCLUDE_TRADE_WITH_OFFER } from '../../constants';

// actions
import { tradeInActionCreators } from '@makemydeal/dr-dash-store';

import ManualTradeIn from './manualTradeIn/ManualTradeIn.interstate';

import { TradeInValuation } from './valuation/TradeInValuation';

const TradeCard = () => {
    const dispatch = useDispatch();
    const isTradeActivityEnabledAfterBootstrap = useSelector(featureToggleSelectors.isTradeActivityEnabledAfterBootstrap);

    const isTradeInCompleted = useSelector(tradeInSelectors.isTradeInCompleted);

    const vin = useSelector(tradeInSelectors.getVin);
    const showVinDecodeError = useSelector(tradeInSelectors.getErrorDecodingVin);
    const isManualFormOpen = useSelector(tradeInSelectors.isManualFormOpen);
    const ownershipType = useSelector(tradeInSelectors.getTradeOwnershipType);
    const isLeaseTrade = ownershipType === LEASE ? true : false;
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const vehicleMake = useSelector(tradeInSelectors.getTradeMake);
    const vehicleModel = useSelector(tradeInSelectors.getTradeModel);
    const vehicleTrim = useSelector(tradeInSelectors.getTradeTrim);

    const shouldIncludeTradeIn = useSelector(tradeInSelectors.getShouldIncludeTradeIn);
    const includeTradeChecked = shouldIncludeTradeIn ? 'includeTrade' : '';

    const vAutoTriforceWidgetData = useSelector(vAutoTriforceSelectors.getVAutoTriforceData);
    const vAutoTriforceScriptUrl = useSelector(configSelectors.getVAutoTriforceScriptUrl);

    const handleTrade = () => {
        if (isTradeActivityEnabledAfterBootstrap) {
            dispatch(tradeInActionCreators.toggleManualTradeForm());
        }
    };

    const getTradeInTitle = (): string => {
        if (isTradeInCompleted && !isManualFormOpen) {
            return 'View/Edit';
        } else if (!isTradeInCompleted && !isManualFormOpen) {
            return 'Add';
        } else {
            return '';
        }
    };

    const handleCheckboxChange: InterstateOnChangeCallback<CheckBoxEventValue> = (
        event: InterstateOnChangeEvent<CheckBoxEventValue>
    ) => {
        /* istanbul ignore next */
        const isTradeInIncluded = event.target.value?.checked;
        dispatch(tradeInActionCreators.includeTradeChanged(!!isTradeInIncluded));
    };

    return (
        <LoadingSectionParent>
            <PaymentLoading testId="trade-loading-indicator" />
            <Card
                data-testid="trade-card"
                header={
                    <CardHeader
                        title={<Typography variant="h5">Trade-In</Typography>}
                        action={
                            <Action onClick={handleTrade} data-testid="trade-card-edit-action">
                                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                                    {getTradeInTitle()}
                                </Typography>
                            </Action>
                        }
                    />
                }
                content={
                    <FlexCol>
                        {showVinDecodeError && (
                            <Alert type="error" role="alert">
                                <Typography variant="body-sm" tag="span">
                                    There was a problem decoding the VIN. Please try again.
                                </Typography>
                            </Alert>
                        )}
                        {isLeaseTrade && !isManualFormOpen && (
                            <Badge sx={{ width: 'fit-content' }}>
                                <Typography variant="body-sm" tag="span">
                                    {LEASE_TRADE}
                                </Typography>
                            </Badge>
                        )}
                        {!isManualFormOpen && (
                            <>
                                {isTradeInCompleted ? (
                                    <VehicleDetailContent data-testid="vehicle-detail-content">
                                        <DetailContainer>
                                            <Typography
                                                variant="body-md"
                                                tag="span"
                                                data-testid="trade-card-subtitle"
                                            >{`${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleTrim}`}</Typography>
                                            {vin && (
                                                <Typography variant="body-md" tag="span" data-testid="trade-card-subtitle-vin">
                                                    {`VIN: ${vin}`}
                                                </Typography>
                                            )}
                                        </DetailContainer>
                                        {vAutoTriforceWidgetData && (
                                            <TriforceWidget
                                                displayData={vAutoTriforceWidgetData}
                                                scriptUrl={vAutoTriforceScriptUrl}
                                            />
                                        )}
                                    </VehicleDetailContent>
                                ) : (
                                    <Typography variant="body-md" tag="div">
                                        No vehicle information
                                    </Typography>
                                )}
                            </>
                        )}
                        <ManualTradeIn />
                        {isManualFormOpen && <TradeInSeparator />}
                        <TradeInValuation />
                        <TradeInSeparator />
                        <TradeCheckboxContainer>
                            <CheckBox
                                checked={shouldIncludeTradeIn}
                                label={INCLUDE_TRADE_WITH_OFFER}
                                onChange={handleCheckboxChange}
                                value={includeTradeChecked}
                                disabled={!isTradeInCompleted}
                            />
                            <Typography variant="body-xxs" tag="div">
                                *Vehicle information is required to include the trade with the offer.
                            </Typography>
                        </TradeCheckboxContainer>
                    </FlexCol>
                }
            />
        </LoadingSectionParent>
    );
};

export default TradeCard;
