// externals
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// libraries
import { SelectInput } from '@interstate/components/SelectInput';

import { offerSelectors, paymentFeesSelectors } from '@makemydeal/dr-dash-store';

import { paymentServicesTypes } from '@makemydeal/dr-platform-types';

import { Detail } from './types';
import { useFeesFormContext } from './useFeesFormContext';

import { FeeSelectInputContainer } from './FeeCategorySelectInput.style';
import { StateTree } from '@makemydeal/dr-dash-types';

import { filterFeesById, getFeeCodes } from './utils';

export type ManualAccessoryProfitFieldProps = {
    feeCategory: paymentServicesTypes.FeeCategory;
    feeIndex: number;
    detail: Detail;
};
const getFeesByCategory = (feeCategory: paymentServicesTypes.FeeCategory) => (state: StateTree) => {
    return paymentFeesSelectors.getFeesByCategory(state, feeCategory);
};

export const FeeCategorySelectInput: FC<ManualAccessoryProfitFieldProps> = ({ feeCategory, feeIndex, detail }) => {
    const { getCurrentlySelectedFees, handleInputChange, setDisableFeeSubmit } = useFeesFormContext();

    const allItemizedFees = useSelector(offerSelectors.getCombinedItemizedFees);

    const appliedFeeCodes = getFeeCodes(allItemizedFees);
    const feesByCategory = useSelector(getFeesByCategory(feeCategory));
    const currentSelectedFees = getFeeCodes(getCurrentlySelectedFees());

    const combinedExclusions = [...currentSelectedFees, ...appliedFeeCodes];
    const selectableFees = filterFeesById(feesByCategory, combinedExclusions);

    const [codeValue, setCodeValue] = useState(detail.code);
    const [feeErrorState, setFeeErrorState] = useState(false);

    const selectClass = `fee-category-select-input-${feeCategory}-${feeIndex}`;

    const isExistingFee = codeValue !== 0;

    const fees = (isExistingFee ? feesByCategory : selectableFees).map((fee) => ({
        label: `${fee.feeDescription} - ${fee.id}`,
        value: fee.id.toString()
    }));

    const handleChangeCode = (codeValue: string) => {
        if (!codeValue) return;

        const parsedCodeValue = parseInt(codeValue);

        setCodeValue(parsedCodeValue);

        handleInputChange({ category: detail.category, field: 'feeTypeId', index: feeIndex, value: Number(codeValue) });

        const matchingFeeCategory = feesByCategory.find((feeDetails) => feeDetails.id === parseInt(codeValue));

        /* istanbul ignore next */
        const matchingFeeDescription = matchingFeeCategory?.feeDescription || '';

        if (detail.name !== matchingFeeDescription) {
            handleInputChange({ category: detail.category, field: 'feeName', index: feeIndex, value: matchingFeeDescription });
        }
    };

    useEffect(() => {
        setFeeErrorState(codeValue === 0);
        setDisableFeeSubmit(codeValue === 0);
    }, [codeValue, setDisableFeeSubmit]);

    return (
        <FeeSelectInputContainer>
            <SelectInput
                id={selectClass}
                label={'Code'}
                key={selectClass}
                displayLabel={true}
                data-testid={selectClass}
                displayDeselectOption={false}
                onChange={(e) => handleChangeCode(e.target.value || '')}
                name={`fee-category-select-input`}
                options={fees}
                required={true}
                value={(codeValue || '').toString()}
                errorMessage={feeErrorState ? 'Code is required' : undefined}
                hasError={feeErrorState}
            />
        </FeeSelectInputContainer>
    );
};
