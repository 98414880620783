import { NumericInput } from '@interstate/components/NumericInput';
import { Grid } from '@interstate/components/Grid';
import { useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { DEAL_TYPE } from './types';
import { StyledTypography } from './styled.components';
import React from 'react';

interface OverrideMFItem {
    name: string;
    val: number;
}

const itemsMF: OverrideMFItem[] = [
    { name: 'mf', val: 48 },
    { name: 'mf', val: 60 },
    { name: 'mf', val: 72 }
];

const itemsAPR: OverrideMFItem[] = [
    { name: 'apr', val: 48 },
    { name: 'apr', val: 60 },
    { name: 'apr', val: 72 }
];

export const Override: React.FC = () => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const items = offerType === DEAL_TYPE.FINANCE ? itemsAPR : itemsMF;

    return (
        <>
            <Grid xs={3} className="override-mf" textAlign="center">
                <StyledTypography className="large">Override {offerType === DEAL_TYPE.FINANCE ? 'APR' : 'MF'}</StyledTypography>
            </Grid>
            {items.map((item, index) => (
                <Grid xs={3} key={index} padding="5px 22px">
                    <NumericInput
                        placeholder="0"
                        name={item.name}
                        value={item.val}
                        label={item.name.toUpperCase()}
                        allowDecimal={false}
                        autoInsertCommas={true}
                        minValue={1}
                    />
                </Grid>
            ))}
        </>
    );
};
