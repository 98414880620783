import { NumericInput } from '@interstate/components/NumericInput';
import { GridItem, StyledTypography } from './styled.components';
import { useDispatch, useSelector } from 'react-redux';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { DEAL_TYPE } from './types';
import React from 'react';

interface TermsMonthItem {
    name: string;
    val: number;
    key: number;
}

export const TermsMonth = ({ paymentGridTermList }: { paymentGridTermList: TermsMonthItem[] }) => {
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);

    const handleChanges = (e: InterstateOnChangeEvent<TextInputEventValue>, key: number) => {
        dispatch(offerReduxActionCreators.updatePaymentGridTerm(offerType, key, e.target.value));
    };

    return (
        <>
            <GridItem
                xs={3}
                className="header"
                sx={{
                    background: '#f4f4f4'
                }}
            >
                <StyledTypography className="large" data-testid="cash-down-header">
                    {offerType === DEAL_TYPE.FINANCE ? 'CashDown' : 'Due at Signing'}
                </StyledTypography>
            </GridItem>
            {paymentGridTermList.map((item) => (
                <GridItem xs={3} key={item.val} padding="0 20px">
                    <NumericInput
                        sx={{ width: '100%' }}
                        placeholder="0"
                        data-testid={`term-month-input-${item.name}-${item.val}`}
                        name={item.name}
                        label={item.name}
                        value={item.val}
                        allowDecimal={false}
                        autoInsertCommas={true}
                        minValue={1}
                        onBlur={(e) => handleChanges(e, item.key)}
                    />
                </GridItem>
            ))}
        </>
    );
};
