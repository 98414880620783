export interface ItermItem {
    id?: string;
    sellRate?: number;
    netCashDown?: number;
    dueAtSigning?: number;
    downPayment?: number;
    monthlyPayment?: number;
    isSelected?: boolean;
    isEmpty?: boolean;
}

export interface IPaymentTerm {
    key: number;
    val: number;
    items: ItermItem[];
}

export enum DEAL_TYPE {
    FINANCE = 'finance',
    LEASE = 'lease'
}
