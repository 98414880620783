import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { createContext } from 'react';

import { CustomFeeItem } from './types';

export type FeesFormContextProps = {
    isLease?: boolean;
    handleInputChange: (params: {
        index: number;
        field: 'feeAmount' | 'feeName' | 'capIndicator' | 'feeTypeId' | 'feeDescription';
        value: string | boolean | number;
        category: paymentServicesTypes.FeeCategory;
    }) => void;
    handleDelete: (params: { index: number; category: paymentServicesTypes.FeeCategory; code: number; isDelete: boolean }) => void;
    setDealerFees: (fees: CustomFeeItem[]) => void;
    setGovFees: (fees: CustomFeeItem[]) => void;
    setLenderFees: (fees: CustomFeeItem[]) => void;
    handleAddFee: (props: { category: paymentServicesTypes.FeeCategory; feeItems: CustomFeeItem[] }) => void;
    getIsIncludeInCalc: (props: { category: paymentServicesTypes.FeeCategory; includedInCalc: boolean }) => boolean;
    setDisableFeeSubmit: (value: boolean) => void;
    getCurrentlySelectedFees: () => CustomFeeItem[];
};

export const FeesFormContext = createContext<FeesFormContextProps | null>(null);
